import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { marketingClient } from 'lib/contentful'
import { defaultRevalidateSeconds } from 'lib/env'
import { nantes } from 'lib/fonts'
import greenHouseClient from 'lib/greenhouse'
import { colors } from 'lib/root'
import {
  TypeCareersCategoryFields,
  TypeCareersPagesFields,
} from 'lib/types/ctf-marketing'
import ImageGrid from 'components/ImageGrid'
import Img from 'components/Img'
import JobBoard, { JobFiltersType } from 'components/JobBoard'
import Meta from 'components/Meta'
import ScrollAnimate from 'components/ScrollAnimate'
import CareersLayout from 'components/layouts/CareersLayout'

const CareersPage = ({
  content,
  jobs,
  categories,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const router = useRouter()

  const [initialFilters, setInitialFilters] = useState<
    JobFiltersType | undefined
  >(undefined)

  useEffect(() => {
    if (router.isReady) {
      const {
        location = '',
        category = '',
        search = '',
      } = router.query as JobFiltersType

      setInitialFilters({ location, category, search })
    } else {
      setInitialFilters({})
    }
  }, [router.isReady])

  const onFilterChanged = useCallback(
    (filters: JobFiltersType) => {
      const query = Object.fromEntries(
        Object.entries(filters).filter(([, value]) => value),
      )

      router.push({ query }, undefined, {
        shallow: true,
      })
    },
    [router],
  )

  const thickBorderStyles = {
    borderTop: `3px solid ${colors.core.grey[700]}`,
  }

  return (
    <CareersLayout categories={categories}>
      <ScrollAnimate>
        <Grid
          container
          sx={{
            flexDirection: { xs: 'column-reverse', md: 'row' },
            m: 'auto',
            px: { xs: 0, md: 8 },
          }}
          maxWidth={'xl'}
        >
          <Grid
            container
            item
            sx={{
              flexDirection: 'column',
              width: { xs: '100%', md: '50%' },
              justifyContent: 'center',
              alignItems: 'flex-start',
              mt: { xs: 4, md: 0 },
              pl: { xs: 3, md: 0 },
              pr: { xs: 3, md: 8 },
            }}
          >
            {content.heroEyebrow && (
              <Typography
                component={'h2'}
                variant={'overline'}
                sx={{ fontSize: '1rem' }}
              >
                {content.heroEyebrow}
              </Typography>
            )}
            <Typography
              component={'h1'}
              variant={'h3'}
              sx={{ color: colors.core.copper[600], my: 1.5 }}
            >
              {content.heroTitle}
            </Typography>
            <Typography
              variant={'body1'}
              sx={{
                fontFamily: nantes.style.fontFamily,
                color: colors.core.grey[700],
              }}
            >
              {content.heroDescription}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: { xs: '100%', md: 340 }, mt: 4.5, mb: 3, py: 2 }}
              href={'/careers/jobs'}
            >
              See open jobs
            </Button>
          </Grid>
          <Img
            src={content.heroImage}
            sx={{
              width: { xs: '100%', md: '50%' },
              aspectRatio: { xs: 390 / 260, md: 1 },
              maxHeight: { xs: 260, md: 577 },
            }}
          ></Img>
        </Grid>
      </ScrollAnimate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'column' },
        }}
      >
        <ScrollAnimate>
          <Container maxWidth="xl">
            <Box
              component="section"
              sx={[
                {
                  display: { md: 'flex' },
                  justifyContent: { sm: 'space-between' },
                  mt: { xs: 3, md: 5 },
                  mb: { xs: 3, md: 4 },
                  pt: { xs: 4, md: 5 },
                },
                thickBorderStyles,
              ]}
            >
              <Typography
                component="h2"
                variant="h3"
                sx={[
                  {
                    fontSize: { xs: '2rem', md: '2.5rem' },
                    color: colors.core.copper[600],
                    width: { xs: '100%', md: '50%' },
                    mb: { xs: 2, md: 0 },
                  },
                ]}
              >
                {content.gridImagesTitle}
              </Typography>
              <Typography
                sx={[
                  {
                    fontFamily: nantes.style.fontFamily,
                    width: { xs: '100%', md: '50%' },
                    pl: { xs: 0, md: 5 },
                  },
                ]}
              >
                {content.gridImagesDescription}
              </Typography>
            </Box>
          </Container>
        </ScrollAnimate>
        <ImageGrid list={content.gridImages} altList={content.gridImagesAlt} />
      </Box>
      <ScrollAnimate>
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            mt: { xs: 3, md: 4 },
          }}
        >
          <Img
            src={content.locationImage}
            sx={{
              width: { xs: '100%', md: '50%' },
              objectFit: 'cover',
              aspectRatio: { xs: 347 / 260, md: 592 / 436 },
            }}
          />
          <Box sx={{ width: { xs: '100%', md: '50%' }, pl: { xs: 0, md: 8 } }}>
            <Typography
              component="h2"
              variant="h3"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                color: colors.core.copper[600],
                mt: { xs: 2, md: 0 },
                mb: 1.5,
              }}
            >
              {content.locationTitle}
            </Typography>
            {content.locationList.map((item) => (
              <Typography
                component="div"
                key={item}
                sx={{
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  py: { xs: 1.5, md: 1.5 },
                  '&:not(:last-of-type)': {
                    borderBottom: `1px solid`,
                  },
                }}
              >
                {item}
              </Typography>
            ))}

            <Button
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', md: 340 },
                mt: { xs: 2.5, md: 3 },
                py: 2,
              }}
              href="/careers/jobs"
            >
              See open jobs
            </Button>
          </Box>
        </Container>
      </ScrollAnimate>
      <ScrollAnimate>
        <Container maxWidth="xl">
          <Box
            component="section"
            sx={[
              {
                display: { md: 'flex' },
                justifyContent: { sm: 'space-between' },
                mt: { xs: 3, md: 5 },
                pt: { xs: 4, md: 5 },
              },
              thickBorderStyles,
            ]}
          >
            <Typography
              component="h2"
              variant="h3"
              sx={[
                {
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  color: colors.core.copper[600],
                  width: { xs: '100%', md: '50%' },
                  mb: { xs: 4, md: 0 },
                },
              ]}
            >
              {content.benefitsTitle}
            </Typography>
            <Typography
              sx={[
                {
                  fontFamily: nantes.style.fontFamily,
                  fontSize: '1.5rem',
                  width: { xs: '100%', md: '50%' },
                  pl: { xs: 0, md: 5 },
                },
              ]}
            >
              {content.benefitsDescription}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 0, md: 4 },
            }}
          >
            {content.benefitsList.map((benefit) => (
              <Box
                key={benefit.title}
                sx={{
                  flexBasis: { xs: 'auto', md: 0 },
                  flexGrow: 1,
                  mt: 4,
                  pt: 2,
                  borderTop: `1px solid ${colors.core.grey[700]}`,
                }}
              >
                <Typography
                  component={'h3'}
                  variant={'h5'}
                  sx={{ fontSize: '1.6875rem', mb: 1 }}
                >
                  {benefit.title}
                </Typography>
                <Typography sx={{ fontSize: { xs: '1.25rem' } }}>
                  {benefit.description}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: { xs: '100%', md: 340 },
              mt: { xs: 5, md: 5 },
              mb: { xs: 3, md: 5 },
              py: 2,
            }}
            href="/careers/jobs"
          >
            See open jobs
          </Button>
        </Container>
      </ScrollAnimate>
      <ScrollAnimate>
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            mt: { xs: 3, md: 0 },
          }}
        >
          <Img
            src={content.lookingForwardToImage}
            sx={{
              width: { xs: '100%', md: '50%' },
              objectFit: 'cover',
              aspectRatio: { xs: 347 / 260, md: 640 / 348 },
            }}
          />
          <Box sx={{ width: { xs: '100%', md: '50%' }, pl: { xs: 0, md: 8 } }}>
            <Typography
              component="h2"
              variant="h3"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                color: colors.core.copper[600],
                mt: { xs: 2, md: 0 },
                mb: 1.5,
              }}
            >
              {content.lookingForwardToTitle}
            </Typography>
            {content.lookingForwardToList.map((item) => (
              <Typography
                key={item}
                sx={{
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  py: { xs: 1.5, md: 1.5 },
                  '&:not(:last-of-type)': {
                    borderBottom: `1px solid`,
                  },
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Container>
      </ScrollAnimate>

      {initialFilters && (
        <JobBoard
          title={content.jobBoardTitle}
          subTitle={content.jobBoardFilterHeadline}
          images={content.jobBoardImages}
          jobs={jobs}
          initialFilters={initialFilters}
          onFilterChanged={onFilterChanged}
        />
      )}

      {content.seoMetadata && <Meta {...content.seoMetadata.fields} />}
    </CareersLayout>
  )
}

export const getStaticProps = async () => {
  const [jobs, locations] = await Promise.all([
    greenHouseClient.getJobs(),
    greenHouseClient.getLocations(process.env.GREENHOUSE_HARVEST_TOKEN),
  ])

  if (jobs.error) {
    throw Error(`Jobs - ${jobs.error}`)
  }
  if (locations.error) {
    throw Error(`Locations - ${locations.error}`)
  }

  const foundCategories: string[] = []
  const categories: {
    url: string
    label: string
  }[] = []

  const jobPosts = greenHouseClient.jobAdapter(
    jobs.jobs.filter((job) => job.offices.length),
    locations.data,
  )

  const categoryEntries =
    await marketingClient.getEntries<TypeCareersCategoryFields>({
      content_type: process.env.NEXT_PUBLIC_CTF_ENTRIES_CAREERS_CATEGORY,
      select: ['fields.categorySlug'],
    })

  const contentfulCategorySlugs = categoryEntries.items
    ?.map((f) => f.fields)
    ?.map((s) => s.categorySlug)

  jobPosts.forEach((job) => {
    const normalizedCategory = job.category?.replace(/\s+/, '-').toLowerCase()

    if (!contentfulCategorySlugs.includes(normalizedCategory)) {
      return
    }

    if (!foundCategories.includes(normalizedCategory)) {
      foundCategories.push(normalizedCategory)
      categories.push({
        url: `/careers/${normalizedCategory}`,
        label: job.category,
      })
    }
  })

  const { fields } = await marketingClient.getEntry<TypeCareersPagesFields>(
    process.env.NEXT_PUBLIC_CTF_ENTRIES_CAREERS,
  )

  return {
    props: {
      content: {
        heroImage: fields.homeHeroImage.fields.file.url,
        heroEyebrow: fields.homeHeroEyebrow || '',
        heroTitle: fields.homeHeroTitle,
        heroDescription: fields.homeHeroDescription,
        gridImagesTitle: fields.homeGridImagesTitle,
        gridImagesDescription: fields.homeGridImagesDescription,
        gridImages: fields.homeGridImages,
        gridImagesAlt: fields.homeGridImagesAlt,
        locationImage: fields.homeLocationImage.fields.file.url,
        locationTitle: fields.homeLocationTitle,
        locationList: fields.homeLocationList,
        benefitsTitle: fields.homeBenefitsTitle,
        benefitsDescription: fields.homeBenefitsDescription,
        benefitsList: fields.homeBenefitsList,
        lookingForwardToImage:
          fields.homeLookingForwardToWorkImage.fields.file.url,
        lookingForwardToTitle: fields.homeLookingForwardToWorkTitle,
        lookingForwardToList: fields.homeLookingForwardToWorkList,
        jobBoardTitle: fields.homeJobBoardTitle,
        jobBoardFilterHeadline: fields.homeJobBoardFilterHeadline,
        jobBoardImages:
          fields.homeJobBoardImages?.map(
            (image) => `${image.fields.file.url}?w=640&h=640`,
          ) || [],
        seoMetadata: fields.homeSeoMetadata || null,
      },
      jobs: jobPosts,
      categories,
    },
    revalidate: defaultRevalidateSeconds,
  }
}

export default CareersPage
